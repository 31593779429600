import React, { Component } from "react";

class TooltipInput extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      editMode: false,
      text: this.localeRound(props.text),
      readable: this.toSekHumanReadable(props.text),
      editedText: props.text
    };

    this.textInput = null;

    this.setTextInputRef = element => {
      this.textInput = element;
    };

    this.focusTextInput = () => {
      // Focus the text input using the raw DOM API
      if (this.textInput) this.textInput.focus();
    };

    this.beginEdit = this.beginEdit.bind(this);
    this.doneEditing = this.doneEditing.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.handleEditEvent = this.handleEditEvent.bind(this);
    this.handleEnterPress = this.handleEnterPress.bind(this);
  }

  beginEdit = event => {
    this.setState({ editMode: true }, () => {
      this.textInput.value = this.state.text;
      this.focusTextInput();
    });
  };

  doneEditing = event => {
    if (this.state.editedText) {
      // type "number" and "required" makes the editedText blank
      // if it's an invalid number
      this.setState({
        editMode: false,
        text: this.state.editedText,
        readable: this.toSekHumanReadable(this.state.editedText)
      });
    }
  };

  cancelEdit = event => {
    event.preventDefault();
    this.setState({ editMode: false });
  };

  handleEditEvent = event => {
    event.preventDefault();
    this.setState({ editedText: event.target.value });
  };

  handleEnterPress = event => {
    if (event.key === "Enter") {
      if (
        typeof this.state.editedText === "undefined" ||
        this.state.editedText === ""
      ) {
        return;
      }
      return this.doneEditing(event);
    }
  };

  render() {
    return <div className="fade-in">{this.renderTextOrInput()}</div>;
  }

  renderTextOrInput() {
    if (this.state.editMode) {
      return this.renderInput();
    }
    return this.renderTextWithTooltip();
  }

  renderTextWithTooltip() {
    const { iconName, tooltipText } = this.props;
    return (
      <>
        {this.state.readable}
        <span
          className="tooltip has-tooltip-right tooltip-icon has-tooltip-multiline do-not-print"
          data-tooltip={tooltipText}
          onClick={this.beginEdit}
        >
          <i className={"fas " + iconName} />
        </span>
      </>
    );
  }

  renderInput() {
    return (
      <div className="field has-addons">
        <div className="control is-expanded">
          <input
            id="amount"
            ref={this.setTextInputRef}
            onKeyPress={this.handleEnterPress}
            type="number"
            required
            className="input has-background-light"
            placeholder="Verkligt belopp"
            onChange={this.handleEditEvent}
          />
        </div>
        <div className="control">
          <button
            id="amount-abort"
            className="button is-danger"
            name="abort"
            onClick={this.cancelEdit}
          >
            <i className="fas fa-times" />
          </button>
        </div>
        <div className="control">
          <button
            id="amount-submit"
            className="button is-primary"
            name="submit"
            onClick={this.doneEditing}
          >
            <i className="fas fa-check" />
          </button>
        </div>
      </div>
    );
  }

  toSekHumanReadable = number => {
    const formatter = new Intl.NumberFormat("sv-SE", {
      style: "currency",
      currency: "SEK"
    });
    return formatter.format(number);
  };
  localeRound = number => {
    // en-US to get a number that is acceptable in the text field
    const formatter = new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    return formatter.format(number);
  };
}

export default TooltipInput;
