import React from "react";

class Hero extends React.Component {
  render() {
    return (
      <section className="hero is-primary">
        <div className="hero-body do-not-print">
          <h1 className="title">{this.props.title}</h1>
        </div>
      </section>
    );
  }
}

export default Hero;
