import React, { Component } from "react";

class TxIdInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinning: false,
      txid: ""
    };
    this.fetchTransaction = this.fetchTransaction.bind(this);
    this.updateTextField = this.updateTextField.bind(this);
  }

  addSampleTx = event => {
    event.preventDefault();
    this.setState({
      // Needs to be updated every 3 months in order to not be to old for
      // the bitcoinaverage plan
      // A good example tx has small amounts with one input and two outputs.
      txid: "ceff74ee129c235c853b9730a802ba64e4ce9c2895c4135f0ed43742a2cbc7a8"
    });
  };

  fetchTransaction = event => {
    if (event.key === "Enter" || event.type === "click") {
      event.preventDefault();
      if (typeof this.state.txid === "undefined" || this.state.txid === "") {
        return;
      }
      this.setState({ spinning: true }, async () => {
        await this.props.fetchTransactionData(this.state.txid);
        this.setState({ spinning: false });
      });
    }
  };

  updateTextField = event => {
    this.setState({ txid: event.target.value });
  };

  render() {
    const { txid, spinning } = this.state;
    return (
      <section className="first-tighter_section do-not-print">
        <div className="content is-fluid more-space-bottom">
          <h1 className="title">Har du gjort ett köp med Bitcoin?</h1>
          <h2 className="subtitle">
            BitDok hjälper dig att skapa ett underlag till din bokföring.
          </h2>
          <p className="is-clipped">
            Om man som svenskt företag handlar något med Bitcoin så kan det vara
            krångligt att ta fram ett tydligt underlag till sin bokföring. Det
            är detta problem som BitDok löser! Du behöver bara veta vilket id
            din bitcointransaktion har.
          </p>
        </div>
        <div className="content is-fluid space-bottom">
          <div className="field">
            <div className="is-pulled-right sample_label">
              <div className="column">
                <button
                  onClick={this.addSampleTx}
                  className="button-link is-size-7 is-pulled-right"
                >
                  Exempel
                </button>
              </div>
            </div>
            <div className="margin-bottom">
              <p>
                Klistra in transaktions-id för din bitcoinbetalning här nedan!
                (Eller klicka på 'Exempel')
              </p>
            </div>
            <div className="field has-addons">
              <div className="control is-expanded">
                <input
                  id="txid"
                  value={txid}
                  onKeyPress={this.fetchTransaction}
                  type="text"
                  className="input has-background-light"
                  placeholder="Transaktions-id"
                  onChange={this.updateTextField}
                />
                <p id="seed_error_msg" className="help is-danger">
                  {this.props.errorMsg}
                </p>
              </div>
              <div className="control">
                <button
                  id="seed-submit"
                  className={
                    "button is-primary" + (spinning ? " is-loading" : "")
                  }
                  name="submit"
                  onClick={this.fetchTransaction}
                >
                  Sök
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default TxIdInput;
