import React, { Component } from "react";
import Hero from "./components/Hero";
import "./css/theme.css";
import TxIdInput from "./components/TxIdInput";
import TxInfo from "./components/TxInfo";
import TxFilter from "./components/TxFilter";
import ErrorBoundary from "./components/ErrorBoundary";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMsg: ""
    };
  }

  fetchTransactionData = async rawTxid => {
    let txId = rawTxid.trim();
    let isSame =
      this.state.transaction && !(this.state.transaction.txid !== txId);
    if (isSame) return;

    const url = "/api/parsetransaction/" + txId;
    return fetch(url)
      .then(res => res.json())
      .then(this.parseFetchedTransation)
      .catch(error => {
        throw new Error(error);
      });
  };

  parseFetchedTransation = json => {
    if (json.the_result) {
      let firstOutputIfOnlyOneExists =
        json.the_result.outputs.length === 1 ? 0 : null;
      let incomingTransaction = {
        transaction: json.the_result,
        indexOfSelectedOutput: firstOutputIfOnlyOneExists
      };
      this.setState(incomingTransaction);
    } else {
      let error = JSON.stringify(json.the_error);
      this.setState({
        error: error,
        transaction: null,
        indexOfSelectedOutput: null
      });
    }
    return null;
  };

  // When an output row is clicked
  setOutputIndex = event => {
    event.preventDefault();
    let index = event.currentTarget.id;
    this.setState({ indexOfSelectedOutput: index });
  };

  renderTxFilter() {
    if (this.state.transaction) {
      return (
        <TxFilter
          transaction={this.state.transaction}
          onRowClick={this.setOutputIndex}
          selectedIndex={this.state.indexOfSelectedOutput}
        />
      );
    } else if (this.state.error) {
      return this.renderError();
    } else {
      return null;
    }
  }

  renderTxInfo() {
    if (this.hasValue(this.state.indexOfSelectedOutput)) {
      return (
        <TxInfo
          transaction={this.state.transaction}
          indexOfSelectedOutput={this.state.indexOfSelectedOutput}
        />
      );
    } else {
      return null;
    }
  }

  hasValue = value => {
    return typeof value !== "undefined" && value !== null && value !== "";
  };

  render() {
    return (
      <>
        <Hero
          title={"Välkommen till BitDok!"}
          printPreview={this.printPreview}
        />
        <ErrorBoundary>
          <TxIdInput
            fetchTransactionData={this.fetchTransactionData}
            errorMsg={this.state.errorMsg}
          />
        </ErrorBoundary>
        <div className={"the-content"}>
          <ErrorBoundary>{this.renderTxFilter()}</ErrorBoundary>
          <ErrorBoundary>{this.renderTxInfo()}</ErrorBoundary>
        </div>
      </>
    );
  }
  renderError() {
    return (
      <section className="tighter_section">
        <div className="content is-fluid">
          <h2 className="has-text-danger">Något gick fel.</h2>
          <p>Felmeddelande: {this.state.error}</p>
        </div>
      </section>
    );
  }
}

export default App;
