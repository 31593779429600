import React from "react";
import QrCode from "./QrCode";
import shortid from "shortid";
import "bulma-tooltip";
import TooltipInput from "./TooltipInput";

const resolutions = {
  day: "dag",
  hour: "timme"
};

class TxInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chainExplorers: [
        { url: "https://blockstream.info/tx/--txid--", name: "Blockstream" },
        {
          url: "https://www.smartbit.com.au/tx/--txid--",
          name: "Smartbit"
        },
        { url: "https://btc.com/--txid--", name: "btc.com" }
      ]
    };
  }

  randomId = () => {
    // In order to re-start css animations you need to
    // re-render the part of the page that has the animation.
    // One way to do this is to change the "key"-attribute of
    // the tag, and this is what we use this method for.
    return shortid.generate();
  };

  theChains = () => {
    return this.state.chainExplorers.map(explorer => {
      const e = { ...explorer };
      e.url = e.url.replace("--txid--", this.props.transaction.txid);
      return e;
    });
  };

  renderVariation = theOutput => {
    const resolution = this.props.transaction.sekRate.resolution;
    if (typeof resolution === undefined || resolution === "minute") {
      return null;
    }
    return (
      <tr>
        <td>
          L&auml;gsta - H&ouml;gsta ({resolutions[resolution]}){" "}
          <span
            className="tooltip has-tooltip-right tooltip-icon has-tooltip-multiline do-not-print"
            data-tooltip={
              "Transaktionens SEK-belopp är ett genomsnitt " +
              "av bitcoinkursen under det angivna tidsintervallet. " +
              "Här anges det lägsta och högsta möjliga värdet. " +
              "Om du vill ha minutprecision, använd BitDok " +
              "inom två timmar efter det att transaktionen skapades."
            }
          >
            <i className="fas fa-info-circle"></i>
          </span>
        </td>
        <td key={this.randomId()}>
          <div>
            {theOutput.sekValueLow} &mdash; {theOutput.sekValueHigh}{" "}
          </div>
        </td>
      </tr>
    );
  };

  render() {
    const {
      txid,
      outputs,
      date,
      fee,
      feeSekValueHumanReadable
    } = this.props.transaction;
    const theOutput = outputs[this.props.indexOfSelectedOutput];

    return (
      <div id="print-this">
        <div className="width-for-a4-printing">
          <section className="tighter_section">
            <div className="media-content">
              <div className="content is-fluid space-bottom">
                <h2 className="title">
                  Underlag för bokföring av Bitcoin-transaktion
                </h2>
              </div>
              <div className="content is-fluid space-bottom">
                <table className="table info-table">
                  <tbody>
                    <tr>
                      <td className="first-col">Bitcoin-transaktionens id</td>
                      <td>
                        <p>{txid}</p>
                      </td>
                    </tr>
                    <tr key={this.randomId()}>
                      <td>Mottagande bitcoinadress</td>
                      <td>
                        <div className="fade-in">{theOutput.address}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Tidpunkt (CET)
                        <span
                          className="tooltip has-tooltip-right tooltip-icon has-tooltip-multiline do-not-print"
                          data-tooltip={
                            "Den tid då transaktionen inkluderades " +
                            "i ett block på Bitcoins blockkedja av " +
                            'en "miner". Detta sker oftast inom tio ' +
                            "minuter från det att transaktionen skapades, " +
                            "men det kan också ta något längre tid."
                          }
                        >
                          <i className="fas fa-info-circle"></i>
                        </span>
                      </td>
                      <td>{date}</td>
                    </tr>
                    <tr>
                      <td>Belopp, BTC</td>
                      <td key={this.randomId()}>
                        <div className="fade-in">{theOutput.value} BTC</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Belopp, SEK
                        <span
                          className="tooltip has-tooltip-right tooltip-icon has-tooltip-multiline do-not-print"
                          data-tooltip={
                            "Ungefärligt belopp i SEK vid tidpunkten för transaktionen"
                          }
                        >
                          <i className="fas fa-info-circle"></i>
                        </span>
                      </td>
                      <td key={this.randomId()}>
                        <TooltipInput
                          iconName="fa-edit"
                          text={theOutput.sekValue}
                          tooltipText={
                            "Står det ett SEK-belopp på fakturan/kvittot? " +
                            "Då kan du klicka här och skriva in det. Annars används det framräknade, ungefärliga, värdet. "
                          }
                        />
                      </td>
                    </tr>
                    {this.renderVariation(theOutput)}
                    <tr>
                      <td>Avgift för hela transaktionen</td>
                      <td>
                        {fee} BTC / {feeSekValueHumanReadable}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="content is-fluid space-bottom is-small">
                <p>
                  <b>Information till dig som är företagare</b>
                </p>
                <p>
                  <button onClick={window.print} className="link-button">
                    Skriv ut
                  </button>{" "}
                  detta dokument till pdf eller papper och bifoga det till din
                  bokföring tillsammans med faktura/kvitto. Fakturan/kvittot
                  skall innehålla bitcointransaktionens id och/eller
                  bitcoin-mottagaradressen. Dessutom skall namnet på ditt
                  företag och namnet på leverantören finnas med.
                </p>
                <p>
                  <b>Information till dig som är revisor/bokförare</b>
                </p>
                <p>
                  Detta dokument fungerar som en del av ett underlag i ett
                  företags bokföring. Det bevisar att en viss
                  bitcoin-transaktion har ägt rum genom att peka ut
                  transaktionen på Bitcoins blockkedja. Detta är publik
                  information och går att verifiera med många olika s.k.
                  “blockutforskare”, som t.ex. <b>blockstream.info</b> och{" "}
                  <b>smartbit.com.au</b> Skanna QR-koderna nedan för
                  direktlänkar eller sök efter “Bitcoin Block Explorer” på
                  Google för ännu fler alternativ.
                </p>
                <p>
                  Växlingskursen Bitcoin/SEK vid tillfället för transaktionen
                  har hämtats från webbplatsen <b>bitcoinaverage.com</b>. Även
                  detta är publikt tillgänglig information, och ytterligare
                  källor kan fås genom att söka efter “bitcoin price history” på
                  Google.
                </p>
                <p>
                  Underlaget behöver kompletteras med faktura/kvitto eller
                  liknande som knyter ihop företaget, leverantören och
                  transaktionen.
                </p>
                <p>Feedback/frågor? bitdok@megabit.se</p>
              </div>
            </div>
          </section>
          <section className="section">
            <div className="container is-fluid">
              <div className="qr-codes">
                {this.theChains().map(explorer => (
                  <QrCode
                    key={explorer.name}
                    code={explorer.url}
                    name={explorer.name}
                  />
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default TxInfo;
