import React, { Component } from "react";
import kjua from "kjua";

class QrCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qr: {}
    };
    this.showQR = this.showQR.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.url !== this.props.url) {
      this.showQR();
    }
  }

  componentDidMount() {
    // if current text value is not equal to the previous, calculate
    // and save new QR code image in el, else ignore
    this.showQR();
  }

  render() {
    return (
      <div>
        <a href={this.props.code} target="new">
          <img
            src={this.state.qr.src}
            alt={this.props.code}
            width="128"
            height="128"
          />
          <p className="explorer-name">{this.props.name}</p>
        </a>
      </div>
    );
  }

  showQR() {
    const qrOptions = {
      // render method: 'canvas' or 'image'
      render: "image",

      // render pixel-perfect lines
      crisp: false,

      // error correction level: 'L', 'M', 'Q' or 'H'
      ecLevel: "H",

      minVersion: 1,

      // size in pixel
      size: 128,

      // code color
      fill: "#333",

      // background color
      back: "#fff",

      // content
      text: this.props.code,

      // roundend corners in pc: 0..100
      rounded: 100,

      // quiet zone in modules
      quiet: 0
    };
    this.setState({ qr: kjua(qrOptions) });
  }
}

export default QrCode;
