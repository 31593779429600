import React from "react";

class TxFilter extends React.Component {
  outputSelectionText = () => {
    const { outputs } = this.props.transaction;
    let text;
    if (outputs.length === 1) {
      text = (
        <>
          Denna transaktion innehåller bara ett belopp (s.k "output"). Det är
          förvalt nedan.
        </>
      );
    } else {
      text = (
        <>
          Denna transaktion innehåller <b>{outputs.length}</b> belopp (s.k
          'outputs'). Välj det som stämmer bäst med ditt köp. (SEK-beloppet
          behöver inte vara exakt, p.g.a små skillnader i växlingskurs
        </>
      );
    }
    return <p>{text}</p>;
  };

  render() {
    const { outputs } = this.props.transaction;
    return (
      <section className="tighter_section do-not-print">
        <div className="content is-fluid">
          {this.outputSelectionText()}
          <div className="subsection">
            <div className="flexcolumns">
              <div className="flexcolumn-item flexcolumn-item-wide">
                <table className="table is-striped is-narrow is-hoverable is-fullwidth">
                  <thead>
                    <tr>
                      <th className="pad-left">Tidpunkt</th>
                      <th className="pad-left">BTC</th>
                      <th className="pad-left">Kurs</th>
                      <th className="pad-left">SEK</th>
                      <th className="pad-left">Address</th>
                    </tr>
                  </thead>

                  <tbody>
                    {outputs.map(output => (
                      <tr
                        onClick={this.props.onRowClick}
                        id={output.n}
                        key={output.n}
                        className="hand"
                      >
                        <td>{this.props.transaction.date}</td>
                        <td>{output.value}</td>
                        <td>{this.props.transaction.sekRateHumanReadable}</td>
                        <td className="pad-left">
                          <p>
                            <b>{output.sekValueHumanReadable}</b>
                          </p>
                        </td>
                        <td className="pad-left">
                          <p>{output.address}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flexcolumn-item">
                <button
                  className="button is-primary do-not-print"
                  onClick={() => window.print()}
                  disabled={this.props.selectedIndex ? "" : "disabled"}
                >
                  Skriv ut
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default TxFilter;
